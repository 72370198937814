<template>
    <div class="homeOrder">
        <div class="title">我的寻源</div>

        <div class="tabss" v-loading="loading">
            <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 97%;">
                <el-tab-pane label="全部寻源" name="first"></el-tab-pane>
                <el-tab-pane label="进行中" name="second"></el-tab-pane>
                <el-tab-pane label="超时" name="third"></el-tab-pane>
                <el-tab-pane label="已取消" name="fourth"></el-tab-pane>
                <el-tab-pane label="完成" name="add"></el-tab-pane>
            </el-tabs>
            <br>
            <el-table :data="tableData" stripe style="width: 100%" border>
                <el-table-column prop="productName" label="产品名称">
                </el-table-column>
                <el-table-column prop="purchaseNum" label="需求数量" header-align="center" align="center">
                </el-table-column>
                <el-table-column prop="ceilingPrice" label="单价上限" header-align="center" align="center">
                </el-table-column>
                <el-table-column prop="orderPrice" label="订单总额" header-align="center" align="center">
                </el-table-column>
                <el-table-column prop="offerNum" label="报价次数" header-align="center" align="center">
                    <template slot-scope="scope">
                        <div class="btn2">{{ scope.row.offerNum }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="gmtDeadline" label="截止时间" header-align="center" align="center" width="180px">
                </el-table-column>
                <el-table-column prop="demandStatus" label="状态" header-align="center" align="center">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.demandStatus == 'PROCESSING'">进行中</el-tag>
                        <el-tag type="warning" v-if="scope.row.demandStatus == 'TIMEOUT'">超时</el-tag>
                        <el-tag type="info" v-if="scope.row.demandStatus == 'CANCEL'">已取消</el-tag>
                        <el-tag type="info" v-if="scope.row.demandStatus == 'FINISHED'">已完成</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="详情" header-align="center" align="center">
                    <template slot-scope="scope">
                        <div class="btn" @click="handleEdit(scope.$index, scope.row)">查看</div>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <paging :total="paging.total" @handleCurrentChange="handleCurrentChange" :page-size="paging.sizi"
                    :currentPage="paging.page"></paging>
        </div>
    </div>
</template>

<script>
import { getSourcingList } from "@/utils/api/myApi/index"
import paging from "@/components/paging.vue";
export default {
    name: 'mySourcing',
    components: {
        paging
    },
    data() {
        return {
            loading:true,
            activeName: 'first',
            // 列表数据
            tableData: [
                {
                    name: '刀头',
                    num: 5000,
                    dj: 120,
                    ze: 56000,
                    cs: 3,
                    time: '2023.05.01 15:30:18',
                    type: 1,
                },
                {
                    name: '刀头',
                    num: 5000,
                    dj: 120,
                    ze: 56000,
                    cs: 3,
                    time: '2023.05.01 15:30:18',
                    type: 2,
                },
                {
                    name: '刀头',
                    num: 5000,
                    dj: 120,
                    ze: 56000,
                    cs: 3,
                    time: '2023.05.01 15:30:18',
                    type: 3,
                },
                {
                    name: '刀头',
                    num: 5000,
                    dj: 120,
                    ze: 56000,
                    cs: 3,
                    time: '2023.05.01 15:30:18',
                    type: 1,
                },
            ],
            // 分页
            paging: {
                sizi: 5,
                page: 1,
                total: 0,
                type:''
            },
        }
    },
    mounted(){
        this.getSourcing()
    },
    methods: {
        handleClick(tab, event) {
            this.loading = true
            console.log(tab.index);
            if(tab.index == 0) this.paging.type = '' 
            if(tab.index == 1) this.paging.type = 'PROCESSING'
            if(tab.index == 2) this.paging.type = 'TIMEOUT'
            if(tab.index == 3) this.paging.type = 'CANCEL'
            if(tab.index == 4) this.paging.type = 'FINISHED'
            this.getSourcing()
        },
        // 查看详情
        handleEdit(index, row) {
            console.log(index, row.demandId);
            window.open(`#/myIndex/mySourcingDetails?demandId=${row.demandId}&actMyNum=3`, '_blank');
        },
        // 获取寻源列表
        async getSourcing(){
            const res = await getSourcingList(this.paging)
            if(res.statusCode == 8201){
                this.tableData = res.data.records
                this.paging.total = res.data.total
                this.loading = false
            }
        },
        // 分页事件
        handleCurrentChange(val) {
            this.loading = true
            this.paging.page = val
            this.getSourcing()
        },

    }
}
</script>
<style lang="less" scoped>
.homeOrder {
    width: 1050px;
    height: auto;

    >.title {
        width: 100%;
        height: 40px;
        background-color: #fff;
        padding: 10px 15px;
        box-sizing: border-box;
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }

    >.tabss {
        width: 100%;
        height: auto;
        padding: 30px 20px;
        box-sizing: border-box;
        background-color: #fff;
        margin-top: 20px;

        .btn {
            width: 40px;
            height: 24px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #FF4242;
            color: #FF4242;
            font-size: 12px;
            text-align: center;
            line-height: 24px;
            cursor: pointer;
            margin: 0 auto;
        }

        .btn2 {
            width: 37px;
            height: 22px;
            background: #FF4242;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #FF4242;
            color: #fff;
            font-size: 12px;
            text-align: center;
            line-height: 20px;
            cursor: pointer;
            margin: 0 auto;
        }
    }
}


.paging {
    width: 100%;
    height: 50px;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}


// 分页样式
/deep/ .el-pagination .active {
    background-color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-pagination:hover .active:hover {
    color: #fff !important;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff;
    border: 1px solid #fff;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #ff4242;
    border: 1px solid #ff4242;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-right:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-left:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-tabs__nav-wrap::after {
    background-color: #fff;
}

/deep/ .el-tabs__item.is-active {
    color: #FF4242;
}

/deep/ .el-tabs__active-bar {
    background-color: #FF4242;
}

/deep/ .el-tabs__item:hover {
    color: #FF4242;
}
</style>